import React from 'react';
import {Button, makeStyles} from '@material-ui/core';
import {Email, Facebook, LinkedIn} from '@material-ui/icons';
import {FacebookShareButton, LinkedinShareButton } from 'react-share';

const useStyles = makeStyles((theme)=>({
    shareContainer:{
        textAlign: 'right'
    },
    icon:{
        fontSize:20
    },
    button:{
        minWidth:0,
        marginLeft: 12,
        padding: 8,
        verticalAlign:'bottom',
        border:0,
        lineHeight: '0',
        cursor:'pointer',
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        '& svg':{
            color: '#fff'
        }
    }
}));

const ShareContainer = ({offerLink}) => {
    const classes= useStyles();
    const mailContent = "Check out this job at NOVOMATIC \n\n I found this job and thought I should share it with you:\n\n "+offerLink;
    return (
        <div className={classes.shareContainer}>
            <a target={'_blank'} href={encodeURI(`mailto:?subject=JobOffer&body=${mailContent}`)}>
                <Button className={classes.button} size='small' variant={'contained'} color={'primary'}>
                    <Email className={classes.icon}/>
                </Button>
            </a>
            <FacebookShareButton className={classes.button}  resetButtonStyle={false} url={offerLink}>
                <Facebook className={classes.icon}/>
            </FacebookShareButton>
            <LinkedinShareButton className={classes.button}  resetButtonStyle={false} url={offerLink}>
               <LinkedIn className={classes.icon}/>
            </LinkedinShareButton>
        </div>
    )
};

export default ShareContainer;
