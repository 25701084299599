import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShareContainer from './ShareContainer';
import TranslatedText from './TranslatedText';

const useStyles = makeStyles((theme) => ({
  paddingDialog: {
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    width: '100%',
    color: 'white',
    backgroundColor: '#001D4C',
    textTransform: 'none',
    fontSize: theme.spacing(2),
    padding: theme.spacing(1.5, 0),
    '& > span > a': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  },
  offerContent: {
    color: '#091E43',
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },

    '& li': {
      paddingLeft: '1em',
      textIndent: '-.7em',
    },

    '& ul li:before': {
      content: '"\\2022"',
      display: 'inline-block',
      color: '#FF9700',
      fontSize: '40px',
      lineHeight: '21px',
      verticalAlign: 'sub',
      paddingRight: '10px',
    },
    '& a': {
      color: '#FF9700',
      textDecoration: 'none',
    },
    '& img': {
      maxWidth: 'calc(100% + 48px)',
      margin: '0 -24px',
    },
  },
  rootDialog: {
    overflowY: 'initial',
      paddingTop: 0,
  },
  dialogPaper: {
    maxWidth: '1000px',
  },
  dialogTitle: {
    fontSize: '32px',
    color: '#091E43',
    fontWeight: '500',
  },
  titleRoot:{
    paddingBottom: 0,
  },
}));

const OfferWindow = (props) => {
  const { onClose, offer, isJobOffer, location } = props;
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      scroll="body"
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={!!offer}
    >
      <DialogTitle classes={{root:classes.titleRoot}} disableTypography>
        <Typography className={classes.dialogTitle} variant="h4">{offer.title}</Typography>
        <Typography variant="caption">
          {offer.department && ` | ${offer.department}`}
          {offer.location && ` | ${offer.location.location_str}`}
          {offer.employment_type && ` | ${offer.employment_type}`}
          {isJobOffer && ` | ${offer.code}`}
        </Typography>
          <ShareContainer offerLink={location && location.href}/>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent classes={{ root: classes.rootDialog }}>
        <div className={classes.offerContent} dangerouslySetInnerHTML={{ __html: offer.full_description }} />
      </DialogContent>
      <DialogActions className={classes.paddingDialog}>
        <Button className={classes.button} variant="contained">
          <a target="_blank" rel="noreferrer" href={offer.application_url}>
            <TranslatedText id="career.offer.applyForJob" />
          </a>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OfferWindow.propTypes = {
  offer: PropTypes.shape({}).isRequired,
  isJobOffer: PropTypes.bool,
  onClose: PropTypes.func,
};
OfferWindow.defaultProps = {
  isJobOffer: false,
  onClose: () => {},
};
export default OfferWindow;
